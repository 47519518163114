import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const FreeThemesPage = () => (
  <div>
    <SEO 
        title="Complimentary Web Themes - 800+ complimentary web themes for you" 
        description="In the event you build a fresh website, it’s critical to get the best style for it. 
        With the Sheephostingbay Web Control Panel it can be done really fast."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">Complimentary <span className="block text-indigo-600 xl:inline">Web Themes </span> <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">800+ complimentary web themes for you</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/installer/themes/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/hepsia-free-themes-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> In the event you build a fresh website, it’s critical to get the best style for it. 
                 With the Sheephostingbay Web Control Panel it can be done really fast. We have for you a selection of more than 800 distinct 
                 site web templates obtainable for 100% free. They’re available with our cloud hosting accounts and are thoroughly 
                 customizable. <br/><br/>
                 <span role="img" aria-label="emoji">✨</span> Almost all of our themes are fashioned only for Sheephostingbay’s solutions and 
                 aren’t obtainable any place else outside the Control Panel. It means that the chances to discover someone else with similar 
                 template just like you are generally small.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/free-themes-04.webp" className="w-60" alt="800+ Complimentary Web Themes" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">800+ Complimentary Web Themes </p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Completely customizable. Automatic Installation<br/><br/>
                                    Inside our Control Panel you can find a variety of 800+ complimentary web themes. They all are developed for 
                                    anybody who desires to swiftly set up a brand new website along with a custom made theme, and not having to 
                                    invest several hours surfing around loads of theme galleries on the web.
                                    <br/><br/>
                                    Our templates are derived from the most widespread varieties of websites – blogs, portfolio pages, community 
                                    forums, web sites of non–profit establishments, e–stores, business web sites, etc.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Complimentary Web app Web Themes</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Grab complimentary web themes for your next web app<br/><br/>
                                    With the tools you have handy within the Sheephostingbay Control Panel, it’s easy to launch a brand–new Joomla™ website 
                                    or maybe a WordPress blog yourself. It’s also possible to select an appealing style for your web site from the 
                                    variety of tailor–made templates.
                                    <br/><br/>
                                    You can obtain a fresh custom made theme with the subsequent web applications: Wordpress, Joomla™, Moodle (e–learning), 
                                    Mambo (CMS) and 4Images (image gallery). Sheephostingbay’s templates are working with all versions of the applications 
                                    and can be obtained at no cost at any moment.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/more-than-40-apps-are-available.webp" className="w-60" alt="Complimentary Web app Web Themes" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/website-builder.webp" className="w-60" alt="Complimentary Site Builder Web Themes" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Complimentary Site Builder Web Themes</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    100+ thoroughly customizable complimentary web themes<br/><br/>
                                    With our Complimentary Web Site Building Tool, you’re able to create your upcoming site totally free, and never have to 
                                    commit money on web site design. You’ve got available over 100 unique website templates, available in distinct styles 
                                    and palettes and which you could revise and customize as you desire.
                                    <br/><br/>
                                    It’s not necessary to comprehend anything concerning HTML or CSS as a way to change the appearance of the templates. You 
                                    can utilize the bundled editor to carry out everything. If you have ever utilized a text–editing application, you will 
                                    have no problem making use of the manager.
                                    </div>
                                </div>
                            </div>
                        </div>       
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default FreeThemesPage
